*{
    margin:0;
    padding:0;
}

body{
    overflow-x:hidden;
}

.App {
 
}









