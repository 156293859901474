
.container_camera_page {
    width: 100%;
    background-color: black;
    background-position: center;
}

.camera_title_block {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

.camera_title {
    font-size: 80px;
    font-weight: bold;
    background: linear-gradient(to top, rgb(207,41,16), rgb(253, 107,2));
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    text-align: center;
}

.camera_second_title {
    font-size: 40px;
    color: #d9d9d9;
    text-align:right;
}

.camera_content_block {
    width:80%;
    margin: 0 auto;
    padding: 0px 0px 100px 0px;
}

@media (max-width: 600px) {
    .camera_title {
        font-size: 40px;
        text-align: center;
        margin: 0 auto;
    }

    .camera_second_title {
        font-size: 20px;
        text-align: center;
        margin: 0 auto;
    }
}
