
.container_map_page{
    width:100%;
    background-color:black;
    background-position:center;
    background-repeat:repeat;
}

.map_title_block {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.map_text {
    font-size: 80px;
    font-weight: bold;
    background: linear-gradient(to top, rgb(207,41,16), rgb(253, 107,2));
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    margin: 0px 0px 0px 100px;
}

.map_place_text {
    font-size: 30px;
    font-weight: bold;
    background: linear-gradient(to top, #ff5300, #ff7200);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    margin:20px 0px 10px 0px;

}

.map_road_text {
    font-size: 40px;
    color: #d9d9d9;
    margin:0px 0px 0px 220px;
}

.map_place_item{
    width:300px;
    height:100px;
    background-color:white;
    border-radius:4px;
    margin:10px 0px 0px 0px;
}
.route_image_block {
    width: 60%;
    margin: 0 auto;
}


@media (max-width: 1000px) {
    .route_image_block {
        margin:0px;
        width:90%;
    }
}

@media (max-width: 600px) {
    .map_title_block img{
        margin:0px;
    }
}

@media (max-width: 600px) { 
    .map_text {
        font-size: 40px;
        text-align:center;
        margin:0 auto;
        
        padding: 0px 0px 0px 0px ;
    }
    .map_road_text {
        font-size: 20px;
        text-align:center;
        margin:0 auto;
    }

}