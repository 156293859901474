
.container_state_page {
    width: 100%;
    background-color: black;
    background-position: center;
    margin: 0px 0px 0px 0px;
}

.state_title_block {
    width: auto;
    display: flex;
    padding: 0px 0px 100px 0px;
    justify-content: center;
}

.state_title {
    font-size: 80px;
    font-weight: bold;
    background: linear-gradient(to top, rgb(207,41,16), rgb(253, 107,2));
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    text-align:center;
}

.state_second_title {
    font-size: 40px;
    color: #d9d9d9;
    text-align:right;
    margin: 0 auto;
}

.state_content_block {
    background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5));
    display:flex;
    width: 70vw;
    background-color: transparent;
    flex-wrap: wrap;
    margin: 0 auto;
    padding: 0px 0px 100px 0px;
}

.state_content_block_item{
    background-color:white;
    max-width:290px;
    max-height:110px;
    height:110px;
    border-radius:5px;
    margin: 5%;
}

.state_place_item{
    width: 50vw;
    min-width:200px;
    max-width:300px;
    min-height:100px;
    max-height:250px;
    border-radius:30px;
    
}

@media (max-width: 548px) {/*980*/
    .state_content_block {
        justify-content: center;
    }
    .state_content_block_item{
        max-height: 150px;
        height: 150px;
    }
    .state_title_block{
        display: flex;
    }
}

@media (max-width: 450px){
    .state_title {
        font-size: 60px;
    }
    .state_second_title {
        font-size: 25px;
    }
}

